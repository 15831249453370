<template>
  <div>
    <vue-dropzone ref='dropzone' id='1' @vdropzone-success='addFile' @paste.native.prevent="pasteMe($event)" contenteditable="true" :options="dropzoneOptions"></vue-dropzone>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
const baseUrl = process.env.VUE_APP_BASE_URL;

export default {
  components: {vueDropzone: vue2Dropzone},
  data: function () {
      return {
          dropzoneOptions: {
              url: baseUrl + '/files',
              thumbnailWidth: 150,
              resizeWidth: 1200,
              resizeHeight: 1200,
              // maxFilesize: 0.5,
              resizeMimeType: 'image/jpeg',
              resizeQuality: 0.8,
              withCredentials: true,
              addRemoveLinks: true,
              acceptedFiles: 'image/*, application/pdf',
              headers: {'X-XSRF-TOKEN': this.$cookies.get('XSRF-TOKEN')}
          },
          fileIds: [],
      }
  },
  methods: {
    addFile(file, response) {
      this.fileIds.push(response.id);
      this.$emit('input', this.fileIds);
    },
    pasteMe(event) {
      console.log(event);
      this.$refs.dropzone.dropzone.paste(event);
    }
  }
}
</script>